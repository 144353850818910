import React from 'react';
import * as Animate from "react-reveal"

export default function GoalCard({ goal }) {

    const { icon, title, subTitle, badge } = goal

    return (
        <Animate.Fade bottom>
            <div className='bg-[#fff] rounded' style={{ boxShadow: "0px 14.2458px 32.9568px rgba(7, 65, 39, 0.08)" }}>
                <div className='p-6 flex flex-col lg:flex-row justify-between items-start'>
                    <div className=' flex mb-8 lg:mb-0 flex-col justify-between w-full pb-6'>
                        <Animate.Fade bottom>
                            <img className='w-[30px] sm:w-[30px] mb-4 md:w-[40px] self-center md:self-start' src={icon} alt='icon' />
                            <div className=' text-center md:text-left mt-3'>
                                <h4 className='font-semibold mb-4 sm:text-base md:text-lg lg:text-xl'>{title}</h4>
                                <p className=' text-cstm-grey text-xs md:text-sm-15 lg:text-base md:leading-6 lg:leading-7'>{subTitle}</p>
                            </div>
                        </Animate.Fade>
                    </div>
                </div>
            </div>
        </Animate.Fade>
    );
}
