import React from "react";
import { Newsletter, Contact } from "../components";
import {
  ReeddiGoals,
  Jumbotron,
  Awards,
  Info,
  Stats,
} from "../components/ImpactAndAwards";
import Layout from "../containers/Layout";

export default function ImpactAndAwards() {
  return (
    <Layout title={"Impacts and Awards"}>
      <Jumbotron />
      <Stats />
      <Info />
      <ReeddiGoals />
      <Awards />
      <Newsletter />
      <Contact />
    </Layout>
  );
}
