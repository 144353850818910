import React from 'react';
import Tag from '../Tag';
import AwardCard from './AwardCard'
import * as Animate from "react-reveal"
import RightArrow from '../RightArrow';

import Img1 from "../../assets/images/iaa/earthshot.png"
import Img2 from "../../assets/images/iaa/asme.png"
import Img3 from "../../assets/images/iaa/rbc.png"
import Img4 from "../../assets/images/iaa/ebl.png"
import Img5 from "../../assets/images/iaa/cisco.png"
import Img6 from "../../assets/images/iaa/mit.png"
import Img7 from "../../assets/images/iaa/techno.webp"
import Img8 from "../../assets/images/iaa/Habitat.webp"
import Img9 from "../../assets/images/iaa/bloomberg.webp"
import Img10 from "../../assets/images/iaa/royal.webp"

const awards = [
    {
        id: '1',
        company: 'EarthShot Prize',
        link: 'https://earthshotprize.org/london-2021/the-earthshot-prize-winners-finalists/climate/',
        date: 'August 2021',
        caption: 'Earthshot Prize Global Finalist - Fix Our Climate Category',
        image: Img1
    },

    {
        id: '9',
        company: 'Bloomberg New Economy',
        date: 'August 2021',
        link: 'https://www.bloomberg.com/company/press/bloomberg-new-economy-announces-2022-catalysts-a-community-of-breakthrough-leaders-reshaping-the-global-agenda/',
        caption: "2022 Class of Bloomberg New Economy Catalyst",
        image: Img9
    },

    {
        id: '10',
        company: 'Royal Academy of Engineering',
        date: 'August 2021',
        link: 'https://africaprize.raeng.org.uk/news-archive/news-2021/africa-prize-announces-finalists-for-continent-s-largest-engineering-innovation-award',
        caption: "Finalists For Continent’s Largest Engineering Innovation Award",
        image: Img10
    },

    {
        id: '3',
        company: 'RBC',
        link: 'https://entrepreneurs.utoronto.ca/announcing-the-2020-rbc-prize-for-innovation-entrepreneurship-winners/',
        date: 'August 2021',
        caption: '2020 RBC Prize for Innovation & Entrepreneurship Winner',
        image: Img3
    },

    {
        id: '2',
        company: 'ASME',
        link: 'https://www.asme.org/about-asme/media-inquiries/press-releases/ishow-regional-winners-announced',
        date: 'August 2021',
        caption: 'ASME Innovation Showcase 2020 USA Winner',
        image: Img2
    },

    {
        id: '4',
        company: 'IEEE Prize',
        link: 'https://empowerabillionlives.org/winners/',
        date: 'August 2021',
        caption: 'IEEE EBL Global Prize for Best Student Team',
        image: Img4
    },

    {
        id: '5',
        company: 'Cisco Prize',
        link: ' https://blogs.cisco.com/news/announcing-the-2019-cisco-global-problem-solver-challenge-winners',
        date: 'August 2021',
        caption: '2019 Cisco Global Problem Challenge Winner',
        image: Img6
    },

    {
        id: '8',
        company: 'Habitat for Humanity',
        date: 'August 2021',
        link: 'https://www.habitat.org/emea/newsroom/2022/habitat-humanitys-sheltertech-reveals-10-startups-joining-its-sub-saharan-africa',
        caption: "International's ShelterTech Sub-Saharan Africa finalist",
        image: Img8
    },

    {
        id: '6',
        company: 'MIT Prize',
        date: 'August 2021',
        link: 'https://cep.mit.edu/2019-finalists',
        caption: '2019 MIT Clean Energy Prize - Energy Delivery Track Winner',
        image: Img5
    },

    {
        id: '7',
        company: 'Bereskin & Parr IP',
        date: 'August 2021',
        link: 'https://www.artsci.utoronto.ca/news/student-makes-final-pitch-techno-impact-centres-entrepreneurship-training-program',
        caption: 'Techno 2019 Bereskin & Parr IP Award',
        image: Img7
    },
]


export default function Awards() {
    return (
        <section className='bg-[#fff]'>
            <div className='w-11/12 mx-auto py-16 lg:py-24 flex flex-col gap-y-16 max-w-7xl'>
                <Animate.Fade bottom>
                    <div className='flex flex-col justify-start  items-center md:items-center gap-y-4'>
                        <h1 className='text-3xl text-center font-semibold  md:text-4xl md:text-left lg:text-5xl'>Some of Our Awards and Recognition</h1>
                    </div>

                    <div className='flex flex-row flex-wrap justify-between items-center bg-white award-top-card rounded-xl px-4 lg:p-6'>
                        <div className='w-full md:w-6/12 lg:w-5/12'>
                            {/* <img src={awards[0].image} alt="Award" className='object-cover w-full h-full rounded-xl' /> */}
                            <div className="rounded-md h-64 sm:h-80 lg:h-96 xl:h-[480px] w-full">
                                <iframe
                                    src="https://player.vimeo.com/video/685537133?h=9e60126d33&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                    frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowfullscreen
                                    style={{ width: "100%", height: "100%", background: "black", borderRadius: "10px" }}
                                    title="EARTHSHOT-CLIMATE-NIGERIA REEDDI CAPSULES.mp4"
                                ></iframe>
                            </div>
                        </div>
                        <div className='w-full lg:w-6/12 mt-4 lg:mt-0 pt-4 pb-8'>
                            <div className=' flex flex-col mb-8 lg:mb-12'>
                                <div className='text-sm lg:text-xs text-cstm-grey font-medium mb-4 lg:mb-6 flex justify-between w-full'>
                                    <p>{awards[0].company}</p>
                                </div>

                                <h4 className='font-semibold text-3xl md:text-3xl lg:text-4xl xl:text-5xl xl:leading-tight w-11/12 lg:w-10/12'>
                                    {awards[0].caption}
                                </h4>
                            </div>

                            <a
                                target={"_blank"}
                                rel="noopener noreferrer"
                                href={awards[0].link}
                                className='w-full text-sm md:text-sm-15 lg:text-base text-center rounded font-medium py-3 lg:py-4 px-6 lg:px-8 text-cstm-green border border-cstm-green ease-in duration-300 hover:bg-cstm-green hover:text-[#fff]'
                            >
                                <span>Read more</span> <RightArrow />
                            </a>
                        </div>
                    </div>

                    <div className='mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-14 lg:gap-y-20'>
                        {awards?.slice(1).map((award) => {
                            return (
                                <AwardCard key={award.id} award={award} />
                            )
                        })}
                    </div>
                </Animate.Fade>
            </div>
        </section>
    );
}
