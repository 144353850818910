import React, { useState } from 'react';
import * as Animate from "react-reveal"
import Img from "../../assets/images/iaa/goal-image.png"
import ImgMobile from "../../assets/images/iaa/goal-image-mobile.webp"

export default function Info() {
    const [showMore, setShowMore] = useState(false)

    return (
        <section className=''>
            <div className='w-11/12 m-auto pt-14 pb-8 lg:pt-24 pb-14 max-w-7xl'>
                <div className='text-sm  text-cstm-grey flex flex-col mb-8'>
                    <Animate.Fade bottom>
                        <h1 className='w-10/12 md:w-full mx-auto md:mx-0 text-3xl font-semibold leading-tight mb-6 lg:mb-8 md:text-3xl text-[#000] lg:text-4xl text-center capitalize'>
                            The impact we’re making in Africa
                        </h1>

                        <div className='hidden md:grid mt-10 mb-6 col-start-1 col-span-3'>
                            <img className='w-full' src={Img} alt='community' />
                        </div>

                        <div className='grid md:hidden mt-10 mb-6 col-start-1 col-span-3'>
                            <img className='w-full' src={ImgMobile} alt='community' />
                        </div>

                        <div className='max-w-7xl self-center flex flex-col justify-start items-start md:text-lg pt-6 lg:pt-12'>
                            <p className='text-sm-15 leading-6 md:text-base lg:text-lg lg:leading-10 md:col-start-1 mb-6 lg:mb-8 col-end-2'>
                                Our goal is to make clean, sustainable, and climate-friendly energy available and affordable
                                for every African irrespective of income level. The energy is delivered in both Alternating
                                current (AC) and Direct Current (DC) which makes the device capable of delivering valuable service in
                                low to high energy operations to power phones, Alternating Current (AC) home electronic gadgets,
                                Alternating Current (AC) or Direct Current (DC) small refrigerators, and household electronics owned
                                by our target customers.
                            </p>


                            <p className='text-sm-15 leading-6 md:text-base lg:text-lg lg:leading-10 mb-4 md:row-start-3 col-start-1 col-end-2'>
                                Our study reveals that more than 40% of the daily expenses of households and businesses
                                in Nigeria are spent on grid electricity and fossil-fuel-generated energy sources. Reeddi
                                reduces this cost to less than 10%. This improves the livelihood and earning capacity of our
                                target market as they can now save more than 30% of their energy expenses and work towards
                                improving their standard of living.
                            </p>

                            <p className='text-sm-15 leading-6 md:text-base lg:text-lg lg:leading-10 md:col-start-2 col-end-3'>
                                Reeddi Capsules (systems) are designed to meet the different energy demands of our
                                customers simply. Simply connect your devices to the Capsule and you have them seamlessly powered.
                            </p>
                        </div>
                    </Animate.Fade>
                </div>
            </div>
        </section>
    )
}