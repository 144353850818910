import React from 'react';
import Tag from '../Tag';
import * as Animate from "react-reveal"
import BannerImg from "../../assets/images/iaa/jumbotron-image.webp"
import AbstractImg from "../../assets/images/iaa/abstract-award.svg"

export default function Jumbotron() {
    return (
        <main className='relative pt-8 md:pt-20  bg-cover bg-center' style={{ backgroundImage: `url(${AbstractImg})`, backgroundSize: "cover" }}>
            <div className='container px-4 md:px-6 xl:px-0 m-auto pt-8 pb-8'>

                <div className='w-full pb-8'>
                    <div className='w-full mb-8 text-center md:w-10/12 lg:w-8/12 mx-auto'>
                        <Animate.Fade bottom>
                            <Tag text='Impact' />
                            <h1 className='font-semibold mt-6 lg:mt-8 mb-6 sm:mb-4 leading-tight text-4xl md:text-4xl lg:text-5xl lg:leading-tight capitalize'>
                                Our Impact is Felt Across the Globe.
                            </h1>
                            <p className='text-base lg:text-lg lg:leading-8 mx-auto w-full md:w-11/12 lg:w-10/12 text-cstm-grey capitalize'>
                            Over 1 billion people presently live without electricity 
                            in the world. To address this, Reeddi has built a portable and clean energy 
                            solution at a price point individuals, households, and businesses can afford.
                            </p>
                        </Animate.Fade>
                    </div>

                    <div className='w-full mt-12 lg:mt-16'>
                        <Animate.Fade bottom>
                            <img className='w-fit' src={BannerImg} alt='iaa' />
                        </Animate.Fade>
                    </div>
                </div>
            </div>

        </main>
    );
}
