import React from 'react';
import GoalCard from './GoalCard';
import Icon1 from "../../assets/images/iaa/idea-icon.svg"
import IconEnergy from "../../assets/images/iaa/energy.png"

import Icon2 from "../../assets/images/iaa/business-icon.svg"
import IconBusiness from "../../assets/images/iaa/business.png"

import Icon3 from "../../assets/images/iaa/innovation-icon.svg"
import IconInnovation from "../../assets/images/iaa/innovation.png"

import Icon4 from "../../assets/images/iaa/action-icon.svg"
import IconClimate from "../../assets/images/iaa/climate.png"

const goals = [
    {
        id: '1',
        icon: Icon1,
        title: 'Affordable, Clean Energy',
        subTitle: 'Reeddi’s goal in Africa is to create access to clean energy and make sustainable living affordable for every African irrespective of income level.',
        badge: IconEnergy
    },

    {
        id: '2',
        icon: Icon2,
        title: 'Business Creation',
        subTitle: 'Reeddi opens up business opportunities for small and medium-scale enterprises (SMEs).',
        badge: IconBusiness
    },
    {
        id: '4',
        icon: Icon4,
        title: 'Climate Action',
        subTitle: 'Reeddi Capsule (one of our innovations) offset a significant amount of greenhouse gas emissions from existing fuel-based energy systems.',
        badge: IconClimate
    },

    {
        id: '3',
        icon: Icon3,
        title: 'Energy-Allied Innovation',
        subTitle: 'We have leveraged data insights from our energy-based product to build allied innovations.',
        badge: IconInnovation
    },
]

export default function ReeddiGoals() {
    return (
        <section className='bg-[#FBFBFB]'>
            <div className='w-11/12 mx-auto py-6 md:py-12 max-w-7xl'>
                <div className='py-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-8'>
                    <GoalCard key={goals[0].id} goal={goals[0]} />
                    <div className='grid grid-cols-1 gap-y-8'>
                        {
                            goals.slice(1, 3).map((goal) => {
                                return (
                                    <GoalCard key={goal.id} goal={goal} />
                                )
                            })
                        }
                    </div>
                    <GoalCard key={goals[3].id} goal={goals[3]} />
                </div>
            </div>
        </section>
    );

}



