import React from 'react';

export default function AwardCard({ award }) {
  return (
    <div className='grid grid-rows-[.5fr_.5fr] overflow-hidden rounded gap-y-4 award-card'>

      <div className=' bg-white'>
        <img className='w-full h-64 lg:h-[23rem] bg-white object-cover' src={award.image} alt={award.date} />
      </div>

      <div className='w-11/12 px-2 m-auto  justify-items-start flex flex-col pt-4 pb-8'>
        <div className=' flex flex-col mb-6 lg:mb-8'>
          <div className='text-xs text-cstm-grey font-medium mb-4 flex justify-between w-full'>
            <p>{award.company}</p>
            {/* <p>{award.date}</p> */}
          </div>

          <h4 className='  font-semibold text-base md:text-lg lg:text-xl lg:leading-8 w-10/12'>{award.caption}</h4>
        </div>

        <a
          target={"_blank"}
          rel="noopener noreferrer"
          href={award.link}
          className='w-full text-sm md:text-sm-15 lg:text-base text-center rounded font-medium py-3 px-4 text-cstm-green border border-cstm-green ease-in duration-300 hover:bg-cstm-green hover:text-[#fff]'
        >
          Read more
        </a>

      </div>

    </div>
  );
}
