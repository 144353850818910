import React from 'react';
import * as Animate from "react-reveal"

const statistics = [
    {
        id: '1',
        caption: '70,000+',
        subTitle: 'Lives impacted and Jobs created'
    },

    {
        id: '2',
        caption: '124,000+',
        subTitle: <>KgCO<sub>2</sub> offset from fuel-based substitutes</>
    },

    {
        id: '3',
        caption: '3,000+',
        subTitle: 'Households and Businesses powered monthly'
    },
]


export default function Stats() {
    return (
        <section className='bg-[#F4F4F4] px-8 md:px-10 lg:px-20 xl:px-24 py-10 lg:py-16 lg:mt-10'>
            <div className='container px-4 md:px-6 xl:px-0 mx-auto'>
                <div className='grid gap-16 sm:grid-cols-2 grid-8 md:grid-cols-2 grid-8 lg:grid-cols-3 gap-x-16'>
                    {
                        statistics.map((stat) => {
                            return (
                                <Animate.Fade bottom>
                                    <div key={stat.id} className='flex flex-col justify-center items-center gap-y-4 '>
                                        <h2 className='text-center font-semibold text-cstm-green text-4xl lg:text-5xl'>{stat.caption}</h2>
                                        <p className='text-center text-[#344054] font-normal lg:font-medium text-base lg:text-lg w-full lg:w-9/12 xl:w-8/12 mx-auto capitalize'>{stat.subTitle}</p>
                                    </div>
                                </Animate.Fade>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    );
}
